<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar
        title="社区资讯"
      />
    </div> -->
    <!-- tab栏 -->
    <van-sticky>
      <van-tabs @click="onClickOnlyOne"
        color="#ed664e"
        title-active-color="#ed664e">
        <van-tab name="全部"
          title="全部">
        </van-tab>
        <van-tab v-for="item in TitleList[0].Children"
          :key="item.TKId"
          :name="item.TKId"
          :title="item.Name">
        </van-tab>
      </van-tabs>
      <!-- <van-tabs @click="topClick"
        v-else
        color="#ed664e"
        title-active-color="#ed664e">
        <van-tab v-for="item in TitleList"
          :key="item.TKId"
          :name="item.TKId"
          :title="item.Name">
          <van-tabs v-if="item.Children.length != '0'"
            v-model="activeName"
            @click="onClick"
            color="#ed664e"
            title-active-color="#ed664e">
            <van-tab v-for="item in item.Children"
              :key="item.TKId"
              :name="item.TKId"
              :title="item.Name">

            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs> -->
    </van-sticky>

    <div class="information">
      <!-- 全部 -->
      <div class="center borderClass"
        v-for="item in list"
        :key="item.index">
        <router-link :to="'/article/detail/' + item.RIId">

          <div class="Content">
            <div style="display: inline-block; width: 33%"
              class="ContentA"
              ref="getHeight">
              <img v-if="item.ThematicUrl"
                style="border-radius:5px;"
                :src="item.ThematicUrl"
                alt="" />
            </div>
            <div style="display: inline-block; width: 63%"
              class="ContentB">
              <div class="centerTitle van-ellipsis--l2">
                {{ item.Title }}
              </div>
            </div>
            <span class="centertime"
              style="float: right; margin-top: -15px;color:black;">{{
              item.IssTime
            }}</span>
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetPartyInfoPage, WeGetInfoKindInfoPage } from "@/api/RealInfo";
export default {
  data () {
    return {
      tkId: 0, //tab栏
      activeName: "",
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        pAKind: 2,
      }, //查询传参
      hidden: true, //没有更多数据了
      TitleList: [],
    };
  },
  methods: {
    // 资讯分类
    getPBActivityList: function () {
      WeGetInfoKindInfoPage({ PAKind: 2, }).then((res) => {
        this.TitleList = res.data.data;
        if (this.TitleList[0].Children != '0') {
          this.listfrom.TKId = 0
          // this.listfrom.TKId = this.TitleList[0].Children[0].TKId
        } else {
          this.listfrom.TKId = this.TitleList[0].TKId
        }
        this.getList();
      })
    },
    // 获取分页列表 
    getList () {
      WeGetPartyInfoPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "http://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    topClick: function (name, title) {
      for (let index = 0; index < this.TitleList.length; index++) {
        const element = this.TitleList[index];
        if (element.Children.length != '0') {
          if (element.TKId == name) {
            this.listfrom.TKId = element.Children[0].TKId
            // this.listfrom.TKId = 0
            this.activeName = element.Children[0].TKId
            this.getList()
          } else {
            this.listfrom.TKId = element.TKId
            this.getList()
          }
        }

      }
    },
    // tab点击切换事件
    onClick (name, title) {
      this.list = []
      this.listfrom.TKId = name
      this.listfrom.page = 1
      this.getList()
    },
    onClickOnlyOne (name, title) {
      this.list = []
      this.listfrom.TKId = name
      this.listfrom.page = 1
      this.getList()
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }

    this.getPBActivityList()
  },
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}
</style>